<template>
  <ib-card :animation="false" class="business-guide-step-card-wrapper justify-content-between">
    <div>
      <div class="d-flex align-items-center">
        <img
          class="card-icon"
          :src="stepData.icon"
          alt="icon-concept"
        >
        <p class="card-title font-outfit-regular">
          {{ stepData.title }}
        </p>
      </div>

      <p class="card-description font-weight-light mt-1">
        {{ stepData.description }}
      </p>
    </div>
    <div>
      <div class="d-flex flex-column">

        <template v-for="item in stepData.items.filter(item => item.enabled === true)">
          <router-link
            :key="item.name"
            :to="getRouteObject(item)"
            class="card-item"
          >
            <div
              @mouseover="item.editVisible = true"
              @mouseleave="item.editVisible = false"
            >
              <p class="card-item-name font-outfit-light">
                {{ item.title }}
              </p>
              <div v-if="!item.editVisible" class="card-item-status d-flex align-items-center">
                <img v-if="!isChecked(item)" class="status-icon" src="../../../../../assets/img/business-guide/status-false.svg" alt="status-false">
                <img v-if="isChecked(item)" class="status-icon" src="../../../../../assets/img/business-guide/status-true.svg" alt="status-true">
              </div>
              <div v-if="item.editVisible" class="font-outfit-medium edit">
                {{ $t('edit') }}
              </div>
            </div>
          </router-link>
        </template>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <ib-button-basic
          color="blue"
          height="40px"
          @click="openIntroTutorial"
        >
          <span class="font-outfit-regular">{{ $t('quickTour') }}</span>
        </ib-button-basic>
      </div>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'BusinessGuideStepCard',

  props: {
    stepData: {
      type: Object,
      default: () => ({
        name: '',
        title: '',
        description: ''
      })
    }
  },

  methods: {
    getRouteObject (item) {
      return { name: `business-guide-${this.stepData.name}-${item.name}` }
    },

    openIntroTutorial () {
      this.$emit('open-intro', this.stepData.name)

      this.$gtm.trackEvent({
        event: 'storymodeTutorial',
        value: this.stepData.name
      })
    },

    isChecked (item) {
      return this.$store.state.idea.storyMode.common.completedSteps[item.completedStepName] === true
    }
  }
}
</script>

<style scoped lang="scss">
.business-guide-step-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 15px 20px;

  @include media-breakpoint-up($md) {
    width: 258px;
  }
}

.card-icon {
  width: 44px;
  margin-right: 25px;
  margin-left: 10px;
}

.card-title {
  font-size: 22px;
}

.card-description {
  font-size: 15px;
  opacity: .7;
  padding: 10px;
}

.card-item {
  text-decoration: none;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 0 10px;
    max-height: 33px;

    &:hover {
      background: #DEEBFF;
    }

    .edit {
      font-size: 12px;
      text-transform: uppercase;
      color: $color-primary;
    }
  }

}

.card-item-name {
  font-size: 16px;
  color: #081E4A;
}

.card-item-status {
  width: 18px;
}

</style>
